import { withFormsy } from 'formsy-react';
import React from 'react';

class Select extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      showErrors = false,
      label = null,
      required = false,
      name,
      link = null,
      defaultOptionTitle = "Choose an item...",
      options = []      // name and value
    } = this.props;

    const hasError = showErrors && errorMessage;

    return (
      <div className={"bp3-form-group"}>
        {label && (
          <label className="bp3-label">
            {label + " "}
            {required && (
              <span className="f5 bp3-text-muted">(required)</span>
            )}
          </label>
        )}
        <div className={"bp3-form-content" + (hasError ? " bp3-intent-danger" : "")}>
          <div className={"bp3-select " + (hasError ? " bp3-intent-danger" : "")}>
            <select id={name}
                    className="bp3-input"
                    dir="auto"
                    onChange={this.changeValue}
                    value={this.props.getValue() || ''} >
              <option defaultValue value="">{defaultOptionTitle}</option>
              {options.map((option, idx) => (
                <option key={idx} value={option.value}>{option.name}</option>
              ))}
            </select>
          </div>
          <div className="bp3-form-helper-text">
            <div className="inline-error">
              {(showErrors && errorMessage) ? (errorMessage) :
                (showErrors && showRequired) ? "Field is required." : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withFormsy(Select);