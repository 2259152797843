import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Icon} from './'
import {Link} from 'gatsby';
import * as Paths from '../constants/Paths'
import { slide as Menu } from 'react-burger-menu'
import MediaQuery from 'react-responsive';
import {getMyUser} from '../state/account/actions'
import CONFIG from '../template-config'

var hasRendered = false;

class Header extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      user: null
    }

  }

  componentDidMount() {
    if (!hasRendered) {
      getMyUser();
      hasRendered = true;
    }
  }

  render() {

    const { loggedIn } = this.props;

    return (
      <>
        <div className="Header grid-row h-align">
          <MediaQuery minWidth={800}>
            <div className="container-fluid inner grid-row v-align">
              <div className="grid-item">
                <Link to={Paths.HOME} className="Header-logo grid-row v-align">
                  <Icon name="logo1"/>
                  <div className="logo-text">
                    {CONFIG.product}
                  </div>
                </Link>
              </div>
              <div className="Header-navbuttons grid-item-3 grid-row r-align ">
                <Link className="nav-item" to={Paths.UPDATES}>Updates</Link>
                <Link className="nav-item" to={Paths.CONTACT}>Contact</Link>
              </div>
              <div className="Header-dashboardbuttons grid-row r-align">
                {loggedIn ? (
                  <Link className="btn btn-secondary" to={Paths.DB_USER_HOME}>Dashboard</Link>
                ) : (
                  <Link className="btn btn-secondary" to={Paths.LOGIN}>Login</Link>
                )}
              </div>
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={800}>
            <div className="inner grid-row v-align">
              <div className="grid-row h-align">
                <Link to={Paths.HOME} className="grid-row v-align Header-logo">
                  <Icon name="logo1"/>
                  <div className="logo-text">
                    {CONFIG.product}
                  </div>
                </Link>
              </div>
            </div>
          </MediaQuery>
        </div>
        <MediaQuery maxWidth={800}>
          <Menu right>
            <Link className="menu-item" to={Paths.LOGIN}>Login</Link>
          </Menu>
        </MediaQuery>
      </>
    );
  }

}

export default connect(
  (state) => {
    return {
      loggedIn: state.account.loggedIn
    }
  },
  (dispatch) => (
    bindActionCreators({

    }, dispatch)
  )
)(Header);
