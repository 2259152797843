import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {checkSession} from '../../state/account/actions'

class SessionProvider extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    const { checkSession, user } = this.props;
    if (!user.id) checkSession();
  }

  render() {

    return (
      <>
        {this.props.children}
      </>
    )
  }

}


export default connect(
  (state) => {
    return {
      user: state.account.user
    }
  },
  (dispatch) => (
    bindActionCreators({
      checkSession
    }, dispatch)
  )
)(SessionProvider);
