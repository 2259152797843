import Formsy from 'formsy-react';
import _ from 'lodash';
import React from 'react';
import {TextInput} from '../form';
import {forgotPassword} from '../../api/'
import * as Paths from '../../constants/Paths'
import { Button } from "@blueprintjs/core";

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      serverError: null
    };

  }

  onValidSubmit(model) {

    const { onSuccess = _.noop, onError = _.noop } = this.props;
    this.setState({isProcessing: true, serverError: null});

    forgotPassword(model.email, window.location.origin + Paths.RESET_PASSWORD)
      .then(() => {
        this.setState({isProcessing: false, serverError: null});
        onSuccess(model);
      })
      .catch((e) => {
        this.setState({isProcessing: false, serverError: e.message});
        onError(e.message);
      });

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  render() {

    const { showErrors, isProcessing, serverError } = this.state;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onInvalidSubmit={() => this.onInvalidSubmit() } >
        <TextInput name="email"
                   showErrors={showErrors}
                   label="Email"
                   type="email"
                   large
                   required />
        {serverError && (
          <div className="pt-callout pt-intent-danger s4">
            {serverError}
          </div>
        )}
        <Button type="submit"
                className="s2"
                rightIcon="arrow-right"
                intent="primary"
                text="Submit"
                large={true}
                loading={isProcessing} />
      </Formsy>
    );
  }
}

export default ForgotPasswordForm;