import { Position, Toaster as BPToaster, Intent } from "@blueprintjs/core";

var Toaster = {};

if (typeof window !== "undefined") {
  Toaster = BPToaster.create({
    className: "my-toaster",
    position: Position.BOTTOM_RIGHT,
  });
}

Toaster.success = (message) => {
  Toaster.show({ message, iconName: "tick", intent: Intent.SUCCESS})
};

Toaster.error = (message) => {
  Toaster.show({ message,  iconName: "warning-sign",  intent: Intent.DANGER })
};


export default Toaster;
