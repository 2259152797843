import Formsy from 'formsy-react';
import _ from 'lodash';
import React from 'react';
import { TextInput } from '../form';
import { resetPassword } from '../../api/'
import { Button } from "@blueprintjs/core";


class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      serverError: null
    };

  }

  onValidSubmit(model) {

    const { onSuccess = _.noop, onError = _.noop, token } = this.props;
    this.setState({isProcessing: true, serverError: null});

    resetPassword(token, model.password1)
      .then(() => {
        this.setState({isProcessing: false, serverError: null});
        onSuccess(model);
      })
      .catch((e) => {
        this.setState({isProcessing: false, serverError: e.message});
        onError(e.message);
      });

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  render() {

    const { showErrors, isProcessing, serverError } = this.state;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onInvalidSubmit={() => this.onInvalidSubmit() } >
        <TextInput name="password1"
               type="password"
               label="Password"
               showErrors={showErrors}
               large
               required />
        <TextInput name="password2"
               label="Password Again"
               type="password"
               showErrors={showErrors}
               large
               required />
        {serverError && (
          <div className="pt-callout pt-intent-danger s4">
            {serverError}
          </div>
        )}
        <Button type="submit"
                className="s2"
                rightIcon="arrow-right"
                intent="primary"
                text="Submit"
                large={true}
                loading={isProcessing}/>
      </Formsy>
    );
  }
}

export default ResetPasswordForm;