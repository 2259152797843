export function getDefaultContext(state) {

  try {

    var defaultContext = state.account.settings.defaultContext.split("-");

    if (defaultContext[0] === "user") {
      return {
        type: "user", id: 0
      };
    }
    else {
      return {
        type: defaultContext[0],
        id: parseInt(defaultContext[1])
      };
    }

  } catch(e) {
    return {
      type: "user", id: 0
    };
  }
}