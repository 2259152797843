import queryString from 'query-string';
import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';

export function checkSession() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me?includesettings&${Math.floor(Math.random() * 99999999999)}`, {
    method: 'GET',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function register(email, password, password2, agreedtoterms) {
  return fetch(`${process.env.GATSBY_API_URL}/register`, {
    method: 'post',
    body: JSON.stringify({email, password, password2, agreedtoterms, redirectUrl: `${window.location.origin}/account/verify/`}),
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function login(email, password, checkPremium = false, getSettings = false) {

  var query = queryString.stringify({
    checkpremium: checkPremium ? 1 : 0,
    includesettings: getSettings ? 1 : 0
  });

  return fetch(`${process.env.GATSBY_API_URL}/login?${query}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({email, password}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function logout() {
  return fetch(`${process.env.GATSBY_API_URL}/user/me/logout`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function resendVerifyEmail(userId, redirectUrl) {
  return fetch(`${process.env.GATSBY_API_URL}/resendverify`, {
    method: 'post',
    body: JSON.stringify({userId, redirectUrl}),
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function verifyAccount(token, userId) {
  return fetch(`${process.env.GATSBY_API_URL}/verify?${queryString.stringify({token, userId})}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function forgotPassword(email, redirectUrl) {
  return fetch(`${process.env.GATSBY_API_URL}/forgotpassword`, {
    method: 'post',
    body: JSON.stringify({email, redirectUrl}),
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function resetPassword(token, password) {
  return fetch(`${process.env.GATSBY_API_URL}/resetpassword`, {
    method: 'post',
    body: JSON.stringify({token, password}),
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function getGoogleAuthRedirect() {
  return fetch(`${process.env.GATSBY_API_URL}/auth/google/getloginurl`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}

export function authWithGoogleCode(code) {
  return fetch(`${process.env.GATSBY_API_URL}/auth/google/authwithcode?code=${code}`, {
    method: 'get',
    credentials: 'include',
    headers
  })
    .then((res) => statusErrorFilter(res))
    .then((res) => res.json())
    .then((res) => apiErrorFilter(res))
}
