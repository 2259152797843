import React from 'react'
import _ from 'lodash'
import ClassNames from 'classnames'

class EditableInput extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isFocused: false,
      isEditing: false,
      value: props.value || "",
      originalValue: props.value || "",
      index: _.isNumber(props.index) ? props.index : null,
      hasTriggeredChange: false,
      hasBeenEdited: false
    };

    this.triggerChange = _.debounce(this.triggerChange.bind(this), props.triggerDebounce || 30);

  }

  componentWillReceiveProps(nextProps) {
    if (
      (_.isNumber(this.state.index) && (this.state.index !== nextProps.index)) ||
      (nextProps.value !== this.state.value && !this.state.isEditing ))
    {
      this.setState({value: nextProps.value, hasBeenEdited: false, hasTriggeredChange: false, originalValue: nextProps.value});
    }
  }

  render() {

    const { isFocused, value } = this.state;
    const { className, placeholder = "", variables = [] } = this.props;
    const html = (!isFocused && value.length === 0) ? placeholder : value;

    var classNames = ClassNames("EditableInput", className);

    return (
      <ContentEditable
        className={classNames}
        ref="element"
        // focused={isFocused}
        variables={variables}
        onKeyDown={(e) => this.onKeyDown(e)}
        onPaste={(newValue) => this.onChange(newValue)}
        html={html}
        onFocus={() => this.onFocus()}
        onBlur={() => this.onBlur() }
        contentEditable="plaintext-only"
        onChange={(e, value) => this.onChange(e.currentTarget.textContent)} // handle innerHTML change
      />
    )
  }

  applyNewValue() {
    const { onChange = _.noop } = this.props;
    const { value, isEditing } = this.state;
    if (isEditing) onChange(value);
  }

  onKeyDown(e) {
    if(e.key == 'Enter'){
      this.triggerChange();
      this.refs.element.refs.element.blur();
    }
    else if(e.key == 'Escape'){
      this.setState({value: this.state.originalValue, hasTriggeredChange: false, hasBeenEdited: false}, () => {
        this.props.onChange(this.state.value);
      });
    }
  }

  onFocus() {
    this.setState({ isFocused: true, isEditing: true });

    var el = this.refs.element.refs.element;
    var sel, range;

    if (window.getSelection && document.createRange) {
      range = document.createRange();
      range.selectNodeContents(el);
      sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }

  }

  onBlur() {

    this.setState({ isFocused: false, isEditing: false });

    if (this.state.hasBeenEdited) {
      this.applyNewValue();
      this.setState({ originalValue: this.state.value });
    }
  }

  onChange(newValue) {

    const { onChange = _.noop, triggerOnFirstEdit = false, triggerEvent } = this.props;
    const { hasTriggeredChange } = this.state;

    if (triggerOnFirstEdit && !hasTriggeredChange) {
      this.setState({value: newValue, hasTriggeredChange: true, hasBeenEdited: true}, () => { onChange(newValue); });
    }
    else if (triggerEvent === "change") {
      this.setState({value: newValue, hasBeenEdited: true}, () => { this.triggerChange(); });
    } else {
      this.setState({value: newValue, hasBeenEdited: true});
    }

  }

  triggerChange() {
    this.setState({originalValue: this.state.value });
    this.props.onChange(this.state.value);
  }

}

class ContentEditable extends React.Component {
  constructor (props) {
    super(props);
    this._onChange  = this._onChange.bind(this);
    this._onPaste   = this._onPaste.bind(this);
  }

  _onChange (ev) {
    const method  = this.getInnerMethod();
    const value   = this.refs.element[method];

    this.props.onChange(ev, value);
  }

  _onPaste(ev) {
    const { onPaste, contentEditable } = this.props;

    if (contentEditable === 'plaintext-only') {
      ev.preventDefault();
      var text = ev.clipboardData.getData("text");
      document.execCommand('insertText', false, text);
    }

    if (onPaste) {
      onPaste(this.refs.element.innerText);
    }

  }

  getInnerMethod () {
    return this.props.contentEditable === 'plaintext-only' ? 'innerText' : 'innerHTML';
  }

  shouldComponentUpdate(nextProps, nextState) {
    const method = this.getInnerMethod();
    return nextProps.html !== this.refs.element[method] || this.props.focused !== nextProps.focused;
  }

  render () {

    const { html, contentEditable, focused, variables = [] } = this.props;
    if (!focused && variables.length > 0) var highlightedHtml = this.mark(variables, html);

    return (
      <div
        {...this.props}
        ref="element"
        dangerouslySetInnerHTML={{__html: highlightedHtml || html}}
        contentEditable={ contentEditable === 'false' ? false : true }
        onInput={ this._onChange }
        onFocus={ this.props.onFocus }
        onBlur={ this.props.onBlur }
        onPaste={ this._onPaste } >
      </div>
    )


  }

  mark(highlighters, opString, markTag = 'mark') {

    if (highlighters.length === 0) return opString;

    try {

      var str = opString;

      highlighters.forEach((highlight) => {

        var highlight = highlight || '';
        var escape = highlight.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');
        var tagStr = '<{tag}>$&</{tag}>';

        str = str.replace(
          RegExp(escape, 'g'),
          tagStr.replace(/{tag}/gi, markTag)
        );

      });

      var negatives = str.match(/\$\{.+?\}/g);

      if (negatives && negatives.length > 0) {
        negatives = negatives.filter((negative) => highlighters.indexOf(negative) === -1);

        negatives.forEach((negative) => {
          str = str.split(negative).join(`<mark class="neg">${negative}</mark>`);
        });
      }

      return str;

    } catch(e) {
      return opString;
    }

  }

}

export default EditableInput;
