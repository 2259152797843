import React from 'react'
import { Toaster } from '../';
import { Intent } from '@blueprintjs/core';
import { resendVerifyEmail } from '../../api'
import queryString from 'query-string';
import * as Paths from '../../constants/Paths'

class ResendVerifyButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      processing: false
    }
  }

  render() {

    const { text = "Resend Account Verification Email" } = this.props;
    const { processing } = this.state;

    return (
      <div className="ResendVerifyButton">
        {processing ? (
          <button className="pt-button pt-disabled" onClick={() => this.onResendEmail()}>Resending...</button>
        ) : (
          <button className="pt-button" onClick={() => this.onResendEmail()}>{text}</button>
        )}
      </div>
    )
  }

  onResendEmail() {

    var query = queryString.parse(window.location.search);

    this.setState({processing: true});

    resendVerifyEmail(query.userId, window.location.origin + Paths.VERIFY)
      .then(() => {

        this.setState({processing: false});

        Toaster.show({
          message: "Successfully resent a new account verification email.",
          iconName: "tick",
          intent: Intent.SUCCESS
        });
      })
      .catch((e) => {

        this.setState({processing: false});

        Toaster.show({
          message: e.message,
          iconName: "warning-sign",
          intent: Intent.DANGER
        });
      })

  }

}

export default ResendVerifyButton;
