import React from 'react'
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import '../scss/entry.scss'
import {Header, Footer, SessionProvider} from '../components'
import ClassNames from 'classnames'
import { Helmet } from "react-helmet"

class LayoutA extends React.Component {

  render() {

    const {
      children,
      className,
      title
    } = this.props;

    var classNames = ClassNames("content-under-header", className);

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Organized Church {title ? " - " + title : ""}</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <SessionProvider >
          <Header />
          <div className={classNames}>
            {children}
          </div>
          <Footer />
        </SessionProvider>
      </>
    )
  }

}

export default LayoutA;
