import _ from 'lodash'

var stripeInputCards = [];
var Stripe, stripe;   // declare Stripe before it's loaded on the page by stripe.js

export default function StripeInputManager() {

  Stripe = window.Stripe;

  if (Stripe && !stripe) stripe = Stripe(process.env.GATSBY_STRIPE_KEY);
  var elements = stripe.elements();

  return {
    createToken: (key) => {
      return new Promise((resolve, reject) => {
        var idxOfCard = _.findIndex(stripeInputCards, {key: key});
        if (idxOfCard !== -1) {
          return stripe.createToken(stripeInputCards[idxOfCard].card).then((result) => {
            if (result.error) reject(result.error.message);
            else resolve(result.token);
          });
        } else {
          reject("Something bad happened!")
        }
      });
    },
    createInput: (key, element, style) => {
      if (stripeInputCards.indexOf(key) === -1) {
        var card = elements.create('card', {style: style});
        card.mount(element);
        stripeInputCards.push({key, card});
        return card;
      }
    },
    removeInput: (key) => {
      var idxOfCard = _.findIndex(stripeInputCards, {key: key});
      if (idxOfCard !== -1) {
        stripeInputCards[idxOfCard].card.unmount();
        stripeInputCards[idxOfCard].card.destroy();
        stripeInputCards.splice(idxOfCard, 1);
      }
    }
  }
}