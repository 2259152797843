import Formsy from 'formsy-react';
import _ from 'lodash';
import React from 'react';
import { Checkbox, TextInput } from '../form';
import { Button } from "@blueprintjs/core";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {register} from '../../state/account/actions';

class RegisterForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      serverError: null
    };

  }

  onValidSubmit(model) {

    const { onSuccess = _.noop, onError = _.noop, hardcodeEmail = null, register } = this.props;
    this.setState({isProcessing: true, serverError: null});

    register(hardcodeEmail || model.email, model.password1, model.password2, model.agreedtoterms)
      .then((res) => {
        this.setState({isProcessing: false, serverError: null});
        onSuccess(res.user);
      })
      .catch((e) => {
        this.setState({isProcessing: false, serverError: e.message});
        onError(e.message);
      });

  }

  onInvalidSubmit() {
    this.setState({ showErrors: true });
  }

  render() {

    const { showErrors, isProcessing, serverError } = this.state;
    const { className, hardcodeEmail = null } = this.props;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model) }
              onInvalidSubmit={() => this.onInvalidSubmit() }
              className={className}>
        {hardcodeEmail ? (
          <div className="bp3-form-group">
            <input type="email" className="bp3-input bp3-disabled bp3-large" defaultValue={hardcodeEmail} />
          </div>
        ) : (
          <TextInput name="email"
            type="email"
            label="Email"
            validationError="This is not a valid email"
            showErrors={showErrors}
            large
            required />
        )}
        <TextInput name="password1"
                   type="password"
                   label="Password"
                   showErrors={showErrors}
                   large
                   required />
        <TextInput name="password2"
                   type="password"
                   label="Password Repeat"
                   showErrors={showErrors}
                   large
                   required />
        <Checkbox name="agreedtoterms"
                  label={<span>I accept the terms and privacy policy.</span>}
                  showErrors={showErrors}
                  inputSize="pt-large"
                  required />
        {serverError && (
          <div className="pt-callout pt-intent-danger s4">
            {serverError}
          </div>
        )}
        <Button type="submit"
                rightIcon="arrow-right"
                intent="primary"
                text="Register"
                large={true}
                loading={isProcessing} />
      </Formsy>
    );
  }
}

export default connect(null,
  (dispatch) => (
    bindActionCreators({
      register
    }, dispatch)
  )
)(RegisterForm);