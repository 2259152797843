import React from 'react';
import StripeInputManager from "../../managers/stripe";

class StripeCard extends React.Component {

  componentDidMount() {
    this.mountCard();
  }

  componentWillUnmount() {
    this.unmountCard();
  }

  mountCard() {
    var style = {
      base: {
        background: "white",
        color: '#32325d',
        "border-radius": '0',
        lineHeight: '18px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    StripeInputManager().createInput(this.props.manager, this.refs.mount, style);

  }

  unmountCard() {
    StripeInputManager().removeInput(this.props.manager)
  }

  render() {

    const {
      label = null,
      name,
      error = null
    } = this.props;

    return (
      <div className={"pt-form-group"}>
        {label && (
          <label className="pt-label" htmlFor={name}>
            {label + " "}
            <span className="f5 pt-text-muted">(required)</span>
          </label>
        )}
        <div className={"pt-form-content"}>
          <div className={"pt-input-group " + (error ? " pt-intent-danger" : "")}>
            <div className="grid-row v-align">
              <div className="grid-item sr2">
                <div ref="mount" className="stripe-input"></div>
              </div>
            </div>
            {error && (<div className="stripe-newcard-error">{error}</div>)}
          </div>
        </div>
      </div>
    );
  }
}

export default StripeCard;