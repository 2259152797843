import React from 'react'
import {Link} from 'gatsby'
import CONFIG from '../template-config'
import * as Paths from '../constants/Paths';

class Footer extends React.Component {

  render() {

    return (
      <footer className="Footer fluid-container wrapper">
        <div className="footer-copyright pt-40">
          <p>{CONFIG.product} is a product of {CONFIG.creator} - ©{CONFIG.year}</p>
        </div>
        <div className="sub-footer-menu pt-40">
          <div><Link to={Paths.PRIVACY_POLICY}>Privacy Policy</Link></div>
          <div><Link to={Paths.TERMS}>Terms and Conditions</Link></div>
        </div>
      </footer>
    )
  }

}

export default Footer;
