import React from 'react';
import { withFormsy } from 'formsy-react';
import {FormGroup} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import moment from 'moment-timezone'

class DateTimeInput extends React.Component {

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      showErrors = false,
      label = null,
      required = false,
      closeOnSelection=true,
      format="YYYY-MM-DD HH:mm",
      timePrecision="minute",
      placeholder,
      large,
      rightContent
    } = this.props;

    var value =
      this.props.getValue() ?
        moment(this.props.getValue()).toDate() : null;

    return (
      <FormGroup
        helperText={(showRequired && showErrors) ? "This is required." : (showErrors && errorMessage ) ? errorMessage : ""}
        intent={showErrors ? "danger" : "none"}
        label={label}
        labelInfo={required ? "(required)" : ""}
      >
        <DateInput parseDate={(str, locale) => moment(str, format).toDate()}
                   formatDate={(date, locale) => moment(date).format(format)}
                   closeOnSelection={closeOnSelection}
                   timePrecision={timePrecision}
                   showActionsBar={true}
                   onChange={(momentDate) => this.onChange(momentDate)}
                   value={value}
                   canClearSelection={false}
                   placeholder={placeholder}
                   inputProps={{large, placeholder}} />
        {rightContent}
      </FormGroup>
    );
  }



  onChange(date) {
    this.props.setValue(date);
  }

}

export default withFormsy(DateTimeInput);