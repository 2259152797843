import { withFormsy } from 'formsy-react';
import React from 'react';
import {FormGroup, NumericInput} from "@blueprintjs/core";

class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(valueAsNumber) {
    this.props.setValue(valueAsNumber);
  }

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      type = "text",
      showErrors = false,
      required = false,
      placeholder = "",
      label = "",
      name,
      id,
      large=false,
      autofocus = false,
      link
    } = this.props;

    const hasError = showErrors && errorMessage;

    return (
      <FormGroup
        helperText={(showRequired && showErrors) ? "This is required." : (showErrors && errorMessage ) ? errorMessage : ""}
        intent={showErrors ? "danger" : "none"}
        label={label}
        labelFor={id || name}
        labelInfo={required ? "(required)" : ""}
      >
        <NumericInput id={id || name}
                    name={name}
                    placeholder={placeholder}
                    autoFocus={autofocus}
                    large={large}
                    onValueChange={this.changeValue}
                    type={type}
                    rightElement={link && (
                      <a href={link} className="link-out" target="_blank"><span className={`bp3-icon bp3-icon-share`}></span></a>
                    )}
                    value={this.props.getValue() || 0} />
      </FormGroup>
    );
  }
}

export default withFormsy(TextInput);