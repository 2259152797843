import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';

export function emailSignup(email, category) {
  return fetch(`${process.env.GATSBY_API_URL}/emailsignup`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({email, category}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function enquiry(email, question) {
  return fetch(`${process.env.GATSBY_API_URL}/enquiry`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify({email, question}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}