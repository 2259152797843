import {statusErrorFilter, apiErrorFilter, headers} from './common/apicommons';

export function getAllSettings(ownerType, ownerId) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/settings`, {
    method: 'get',
    credentials: 'include',
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}

export function updateSetting(ownerType, ownerId, key, value) {
  return fetch(`${process.env.GATSBY_API_URL}/${ownerType}/${ownerId}/settings`, {
    method: 'put',
    credentials: 'include',
    body: JSON.stringify({key, value}),
    headers
  })
  .then((res) => statusErrorFilter(res))
  .then((res) => res.json())
  .then((res) => apiErrorFilter(res))
}