import _ from 'lodash'
import React from 'react'

class Money extends React.Component {

  render() {

    var { value = 0, stripe = true, prefix = "$" } = this.props;

    if (!_.isNumber(value)) {
      value = 0;
    }

    if (stripe) {
      value /= 100;
    }

    return (
      <span className="Money">
        {prefix}{value.toFixed(2)}
      </span>
    )
  }

}

export default Money;
