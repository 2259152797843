import _ from 'lodash'

import * as SessionApi from "../../api/SessionApi";
import * as SettingsApi from "../../api/SettingsApi";
import * as UserApi from "../../api/UserApi";
import {switchContext} from "../context/actions";
import * as LayoutManager from "../../managers/layout"
import * as actionTypes from './actionTypes'
import { getDefaultContext } from "./selectors";

export const checkSession = () => (dispatch, state) =>
  SessionApi.checkSession()
    .then((result) => {
      dispatch({ type: actionTypes.SESSION_ACTIVE, user: result.user });
      dispatch({ type: actionTypes.GET_SETTINGS, settings: result.user.settings});
      switchToDefaultOrg()(dispatch, state);
    })
    .catch((e) => dispatch({ type: actionTypes.SESSION_INACTIVE }));

export const switchToDefaultOrg = () => (dispatch, state) => {
  // auto switch to org functionality.  (no auto switch to project implemented)
  var defaultContextId = getDefaultContext(state()).id;

  if (defaultContextId > 0) {
    return switchContext("org", defaultContextId)(dispatch, state)
  }
}

export const login = (email, password) => (dispatch, state) =>
  SessionApi.login(email, password, true, true)
    .then((result) => {
      dispatch({ type: actionTypes.LOGIN, user: result.user });
      dispatch({ type: actionTypes.SET_PREMIUMSTATUS, premium: result.user.premium });
      dispatch({ type: actionTypes.GET_SETTINGS, settings: result.user.settings});
      switchToDefaultOrg()(dispatch, state);
    });

export const register = (email, password, password2, agreedtoterms) => dispatch =>
  SessionApi.register(email, password, password2, agreedtoterms)
    .then((result) => dispatch({ type: actionTypes.REGISTER, user: result.user }));

export const logout = () => dispatch =>
  SessionApi.logout()
    .then((result) => dispatch({ type: actionTypes.LOGOUT }))
    .then(() => LayoutManager.clear());

export const updateUser = (first, last, email) => dispatch =>
  UserApi.updateUser(first, last, email)
    .then(() => getMyUser()(dispatch));

export const getMyUser = () => dispatch =>
  UserApi.getMyUser()
    .then((result) => dispatch({ type: actionTypes.UPDATE_DETAILS, user: result.user }));

export const getSubscription = () => (dispatch, state) =>
  UserApi.getSubscription(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_SUBSCRIPTION, subscription: result.subscription }));

export const cancelSubscription = () => (dispatch, state) =>
  UserApi.cancelSubscription(state().account.subscription.id)
    .then(() => dispatch({ type: actionTypes.CANCEL_SUBSCRIPTION }))
    .then(() => Promise.all([
      getSubscription()(dispatch, state),
      getNextInvoice()(dispatch, state)
    ]));

export const renewSubscription = () => (dispatch, state) =>
  UserApi.renewSubscription(state().account.subscription.id)
    .then(() => dispatch({ type: actionTypes.RENEW_SUBSCRIPTION }))
    .then(() => Promise.all([
      getSubscription()(dispatch, state),
      getNextInvoice()(dispatch, state)
    ]));

export const getPaymentMethod = () => (dispatch, state) =>
  UserApi.getPaymentMethod()
    .then((result) => dispatch({ type: actionTypes.GET_PAYMENTMETHOD, paymentMethod: result.source }));

export const updatePaymentMethod = (token) => (dispatch, state) =>
  UserApi.updatePaymentMethod(token)
    .then((result) => dispatch({ type: actionTypes.UPDATE_PAYMENTMETHOD, paymentMethod: result.source  }));

export const getCharges = () => (dispatch, state) =>
  UserApi.getCharges()
    .then((result) => dispatch({ type: actionTypes.GET_CHARGES, charges: result.charges }));

export const getNextInvoice = () => (dispatch, state) =>
  UserApi.getNextInvoice(state().context.id)
    .then((result) => dispatch({ type: actionTypes.GET_NEXTINVOICE, nextInvoice: result.invoice }));

export const purchasePremium = (token) => (dispatch, state) =>
  UserApi.purchasePremium(token)
    .then((result) => dispatch({
      type: actionTypes.PURCHASE_PREMIUM,
      paymentMethod: result.source,
      charges: result.charges,
      nextInvoice: result.nextInvoice,
      subscription: result.subscription
    }));

export const purchaseTeam = (name, addlseats, token) => (dispatch, state) =>
  UserApi.purchaseTeam(name, addlseats, token)
    .then((result) => dispatch({
      type: actionTypes.PURCHASE_TEAM,
      paymentMethod: result.source,
      charges: result.charges,
      nextInvoice: result.nextInvoice,
      subscription: result.subscription
    }));

export const setUserSetting = (key, value) => (dispatch, state) =>
  SettingsApi.updateSetting("user", "me", key, value)
    .then(() => dispatch({ type: actionTypes.UPDATE_SETTING, key, value }));

export const getAllUserSettings = () => (dispatch) =>
  SettingsApi.getAllSettings("user", "me")
    .then((result) =>
      dispatch({
        type: actionTypes.GET_SETTINGS,
        settings: _.mapValues(_.keyBy(result.settings, 'key'), 'value')
      }));
