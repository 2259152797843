import { withFormsy } from 'formsy-react';
import React from 'react';

class Select extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      showErrors = false,
      name,
      defaultOptionTitle = "Choose an item...",
      options = []      // name and value
    } = this.props;

    const hasError = showErrors && errorMessage;

    return (
      <div className={"bp3-select " + (hasError ? " bp3-intent-danger" : "")}>
        <select id={name}
                className="bp3-input"
                dir="auto"
                onChange={this.changeValue}
                value={this.props.getValue() || ''} >
          <option defaultValue value="">{defaultOptionTitle}</option>
          {options.map((option, idx) => (
            <option key={idx} value={option.value}>{option.name}</option>
          ))}
        </select>
      </div>
    );
  }
}

export default withFormsy(Select);