export const HOME = "/";
export const DOCS = "/doc/";
export const UPDATES = "/updates/";
export const BLOG = "/blog/";
export const CONTACT = "/contact/";
export const PRICING = "/pricing/";
export const PREMIUM = "/premium/";
export const TEAMS = "/teams/";
export const PRIVACY_POLICY = "/privacy-policy/";
export const TERMS = "/terms-of-service/";

export const LOGIN = "/account/login/";
export const REGISTER = "/account/register/";
export const FORGOT_PASSWORD = "/account/forgotpassword/";
export const RESET_PASSWORD = "/account/resetpassword/";
export const PLEASE_VERIFY = "/account/pleaseverify/";
export const VERIFY = "/account/verify/";

export const PROFILE = "/dashboard/user";
export const BILLING = "/dashboard/billing";
export const ORG_OVERVIEW = "/dashboard/user/myorgs";
export const ORG_INFO = "/dashboard/org/info";
export const ORG_CREATE = "/dashboard/org/create";

export const ENTITY_LIST = "/dashboard/entity";
export const ENTITY_EDIT = "/dashboard/entity/:id/edit";

export const SONG_LIST = "/dashboard/songs";
export const SONG_EDIT = "/dashboard/song/:id/edit";

export const SERVICE_LIST = "/dashboard/services";
export const SERVICE_EDIT = "/dashboard/service/:id/edit";

export const PERSON_LIST = "/dashboard/people";
export const PERSON_EDIT = "/dashboard/people/:id/edit";

export const SERMON_LIST = "/dashboard/messages";
export const SERMON_EDIT = "/dashboard/message/:id/edit";

export const TITHE_LIST = "/dashboard/tithe";
export const TITHE_EDIT = "/dashboard/tithe/:id/edit";

export const RESOURCE_LIST = "/dashboard/resources";
export const RESOURCE_EDIT = "/dashboard/resources/:id/edit";

export const TEAM_LIST = "/dashboard/teams";
export const TEAM_EDIT = "/dashboard/teams/:id/edit";

export const CHURCH_OVERVIEW = "/dashboard/";
export const SHARE_OVERVIEW = "/dashboard/share";
export const NOTIFICATION_OVERVIEW = "/dashboard/notifications";

export const DB_USER_HOME = CHURCH_OVERVIEW;

export function withId(path, id, idString = ":id") {
  return path.replace(idString, id);
}

export function pathMatches(path) {

  if (typeof window === "undefined") return false;

  return window.location.pathname === path
    || (window.location.pathname + "/") === path
}