import * as actionTypes from './actionTypes';
import * as Paths from "../../constants/Paths";
import {navigate} from 'gatsby'

export const switchContext = (contextType, contextId) => (dispatch, state) => {
  return new Promise((resolve, reject) => {
    dispatch({type: actionTypes.SET_REFRESHING, refreshing: true});
    dispatch({ type: actionTypes.SWITCH, contextType, contextId });
    setTimeout(() => {
      dispatch({type: actionTypes.SET_REFRESHING, refreshing: false});
      navigate(Paths.DB_USER_HOME);
    }, 500);
    resolve();
  })
};

