export default {

  product: "Organized Church",

  domain: "organized.church",
  supportEmail: "jungyue@gmail.com",
  creator: "Prolific Devs",

  year: "2020",
  seo: {
    faviconUrl: "",
    defaultTitle: "Organized Church"
  },
  tags: {
    fullstory: null,
    ga: null
  }

}
