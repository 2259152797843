import { withFormsy } from 'formsy-react';
import React from 'react';
import {FormGroup} from "@blueprintjs/core";

class HtmlSelect extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      showErrors = false,
      label = null,
      required = false,
      name,
      id,
      large = false,
      options = []
    } = this.props;

    const hasError = showErrors && errorMessage;

    return (
      <FormGroup
        helperText={(showRequired && showErrors) ? "This is required." : (showErrors && errorMessage ) ? errorMessage : ""}
        intent={showErrors ? "danger" : "none"}
        label={label}
        labelFor={id || name}
        labelInfo={required ? "(required)" : ""}
      >
        <div className="bp3-select">
          <select id={name}
                  name={name}
                  dir="auto"
                  className={large ? "bp3-large" : ""}
                  onChange={this.changeValue}
                  value={this.props.getValue() || ''} >
            <option default>Select Journal</option>
            {options.map((option, idx) => (
              <option key={idx} value={option.value}>{option.name}</option>
            ))}
          </select>
        </div>
      </FormGroup>
    );
  }
}

export default withFormsy(HtmlSelect);