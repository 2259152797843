import Formsy from 'formsy-react';
import _ from 'lodash';
import React from 'react';
import {TextInput, Textarea} from '../form';
import {enquiry} from '../../api/'
import {Button} from "@blueprintjs/core";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      serverError: null
    };

  }

  render() {

    const {showErrors, isProcessing, serverError} = this.state;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model)}
              onInvalidSubmit={() => this.onInvalidSubmit()}>
        <TextInput name="email"
               type="email"
               label="Your Email"
               showErrors={showErrors}
               validations="isEmail"
               validationError="This is not a valid email"
               large
               required/>
        <Textarea name="message"
                  showErrors={showErrors}
                  label="Message"
                  required/>
        {serverError && (
          <div className="pt-callout pt-intent-danger s4">
            {serverError}
          </div>
        )}
        <Button type="submit"
                className="s2"
                rightIcon="arrow-right"
                intent="primary"
                text="Send Message"
                large={true}
                loading={isProcessing}/>
      </Formsy>
    );
  }

  onValidSubmit(model) {

    const {onSuccess = _.noop, onError = _.noop} = this.props;
    this.setState({isProcessing: true, serverError: null});

    enquiry(model.email, model.message)
      .then(() => {
        this.setState({isProcessing: false, serverError: null});
        onSuccess();
      })
      .catch((e) => {
        this.setState({isProcessing: false, serverError: e});
        onError(e);
      })

  }

  onInvalidSubmit() {
    this.setState({showErrors: true});
  }

}

export default ContactForm;