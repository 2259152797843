import React from 'react'

class Icon extends React.Component {

  render() {

    const { name = "", className = "" } = this.props;

    return (
      <svg {...this.props} className={'icon icon-' + name + (className ? " " + className : "")} >
        <use xlinkHref={'#icon-' + name} />
      </svg>
    )
  }

}

export default Icon;
