import { withFormsy } from 'formsy-react';
import React from 'react';
import { Switch, FormGroup} from "@blueprintjs/core";

class Checkbox extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.checked);
  }

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      label = "",
      required = false,
      name,
      id,
      showErrors = false
    } = this.props;

    const hasError = showErrors && errorMessage;

    return (
      <FormGroup
        helperText={(showRequired && showErrors) ? "This is required." : (showErrors && errorMessage ) ? errorMessage : ""}
        intent={showErrors ? "danger" : "none"}
        labelFor={id || name}
        labelInfo={required ? "(required)" : ""}
      >
        <Switch checked={this.props.getValue() || ''}
                label={label}
                onChange={this.changeValue}
                id={name}
                name={name} />
      </FormGroup>
    )
  }
}

export default withFormsy(Checkbox);