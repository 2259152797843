import React from 'react'
import _ from 'lodash'
import ClassNames from 'classnames'

class FullHeightCalculator extends React.PureComponent {

  onResize() {
    this.resizeContainer();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResize);
  }

  resizeContainer() {

    const { heightAdjust = 0 } = this.props;

    if (!this.refs.content) return;

    let contentContainer = this.refs.content;
    let offsetTop = this.refs.content.offsetTop;
    let windowHeight = window.innerHeight;

    contentContainer.style.height = (windowHeight - offsetTop + heightAdjust) + "px";
  }

  componentDidMount() {
    this.resizeContainer();
    this.onResize = _.debounce(this.onResize.bind(this), 300);
    window.addEventListener("resize", this.onResize, false);
  }

  render() {

    this.resizeContainer();

    const { className } = this.props;

    const containerClassNames = ClassNames("full-height-container", className);

    return (
      <div className={"full-height-calculator"} ref="content">
        <div className={containerClassNames}>
          {this.props.children}
        </div>
      </div>
    )
  }

}


export default FullHeightCalculator;
