import React from 'react'
import Lightbox from 'react-images';

const CLASS = "Screenshots";

class Screenshots extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      currentImageIdx: 0
    }
  }

  render() {

    const { opened, currentImageIdx } = this.state;
    const { images = [], imagesPerRow = 2 } = this.props;

    var rows = this.buildRows();

    return (
      <div className={`${CLASS}`}>
        <Lightbox
          images={images}
          isOpen={opened}
          currentImage={currentImageIdx}
          onClickPrev={() => this.setState({currentImageIdx: currentImageIdx - 1})}
          onClickNext={() => this.setState({currentImageIdx: currentImageIdx + 1})}
          onClose={() => this.setState({opened: false})}
        />
        {rows.map((images, rowIdx) => (
          <div key={rowIdx} className={`${CLASS}-row`}>
            {images.map((image, idx) => (
              image ?
                <div key={idx}
                     onClick={() => this.onThumbnailClick((rowIdx * imagesPerRow) + idx)}
                     className={`${CLASS}-thumbnail image-wrapper`}>
                  <img src={image.src} />
                </div> :
                <div className={`${CLASS}-thumbnail empty`}></div>
            ))}
          </div>
        ))}
      </div>
    )
  }

  buildRows() {

    const { images = [], imagesPerRow = 2 } = this.props;

    var currentRow = 1;
    var rows = [[]];

    images.forEach((image, idx) => {
      if (idx < imagesPerRow * currentRow) {
        rows[currentRow - 1].push(image)
      } else {
        currentRow++;
        rows[currentRow - 1] = [image]
      }
    });

    rows.forEach((row) => {
      while (row.length < imagesPerRow) {
        row.push(null)
      }
    });

    return rows;

  }

  onThumbnailClick(imageIdx) {
    this.setState({opened: true, currentImageIdx: imageIdx})
  }

}

export default Screenshots;
