import { withFormsy } from 'formsy-react';
import React from 'react';
import {FormGroup, TextArea} from "@blueprintjs/core";
import TextareaAutosize from 'react-textarea-autosize';

class Input extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(event) {
    this.props.setValue(event.currentTarget.value);
  }

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      showErrors = false,
      label = null,
      required = false,
      large = false,
      id,
      name,
      className
    } = this.props;

    const hasError = showErrors && errorMessage;

    return (
      <FormGroup
        helperText={(showRequired && showErrors) ? "This is required." : (showErrors && errorMessage ) ? errorMessage : ""}
        intent={showErrors ? "danger" : "none"}
        label={label}
        labelFor={id || name}
        labelInfo={required ? "(required)" : ""}
        className={className}
      >
        <TextareaAutosize
          onChange={this.changeValue}
          value={this.props.getValue() || ''}
          id={id || name}
          name={name}
          className="bp3-input"
        />
      </FormGroup>
    );
  }
}

export default withFormsy(Input);