var keysLoaded = {};

export const loadOnce = (method, key) => {
  if (!keysLoaded[key]) {
    method().then(() => keysLoaded[key] = true);
  }
}

export const clear = () => {
  keysLoaded = {};
}