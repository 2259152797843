import Formsy from 'formsy-react';
import _ from 'lodash';
import React from 'react';
import {TextInput} from '../form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {login} from '../../state/account/actions';
import {Button} from "@blueprintjs/core";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrors: false,
      isProcessing: false,
      serverError: null
    };

  }

  onValidSubmit(model) {

    const {onSuccess = _.noop, onError = _.noop, login} = this.props;
    this.setState({isProcessing: true, serverError: null});

    login(model.email, model.password)
      .then(() => onSuccess())
      .catch((e) => {
        this.setState({isProcessing: false, serverError: e.message});
        onError();
      });

  }

  onInvalidSubmit() {
    this.setState({showErrors: true});
  }

  render() {

    const {showErrors, isProcessing, serverError} = this.state;

    return (
      <Formsy onValidSubmit={(model) => this.onValidSubmit(model)}
              onInvalidSubmit={() => this.onInvalidSubmit()}>
        <TextInput name="email"
                   type="email"
                   label="Email"
                   showErrors={showErrors}
                   validations="isEmail"
                   validationError="This is not a valid email"
                   large
                   required />
        <TextInput name="password"
               showErrors={showErrors}
               icon="key"
               label="Password"
               type="password"
               large
               required/>
        {serverError ? (
          <div className="bp3-callout pt-intent-danger s4">
            {serverError}
          </div>
        ) : (<div className="s4"></div>)}
        <Button type="submit"
                className="s2"
                rightIcon="arrow-right"
                intent="primary"
                text="Login"
                large={true}
                loading={isProcessing}
        />
      </Formsy>
    );
  }
}

export default connect(null,
  (dispatch) => (bindActionCreators({ login }, dispatch))
)(LoginForm);