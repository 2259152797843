import React from 'react';
import {ContactForm} from "../components/session";
import {LayoutA} from "../layouts";

class ContactPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      messageSent: false
    }
  }

  render() {

    const {messageSent = false} = this.state;

    return (
      <LayoutA>
        <div className="ContactPage LoginTemplate">
          <div className="LoginTemplate-floatingform">
            <h1 className="LoginTemplate-title">Contact Us</h1>
            {messageSent ? (
              <div className="login-form s6 t-center f3 f-strong">
                Thanks! We'll send you an email response ASAP! We are usually very quick.
              </div>
            ) : (
              <div className="login-form s6">
                <p>Get in touch with us! We usually respond very quickly.</p>
                <ContactForm onSuccess={() => this.setState({messageSent: true})}/>
              </div>
            )}
          </div>
        </div>
      </LayoutA>
    );
  }

}

export default ContactPage;