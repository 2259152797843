import { withFormsy } from 'formsy-react';
import React from 'react';
import {TextArea} from "@blueprintjs/core";

class MarkdownInput extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(e) {
    this.props.setValue(event.target.value);
  }

  render() {

    // An error message is returned only if the component is invalid
    const showRequired = this.props.showRequired();

    const {
      showErrors = false,
      required = false,
      label = "",
      className = ""
    } = this.props;

    return (
       <TextArea
         growVertically={true}
         large={true}
         onChange={this.changeValue}
         value={this.props.getValue()}
       />
    );

  }
}

export default withFormsy(MarkdownInput);