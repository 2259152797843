import { withFormsy } from 'formsy-react';
import CreateableSelect from 'react-select/creatable';
import Select from 'react-select';
import React from 'react';

class MultiSelect extends React.Component {

  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
  }

  changeValue(selectedOption) {
    this.props.setValue(selectedOption);
  }

  render() {

    // An error message is returned only if the component is invalid
    const errorMessage = this.props.getErrorMessage();
    const showRequired = this.props.showRequired();

    const {
      showErrors = false,
      label = null,
      required = false,
      name,
      options = [],      // name and value
      disabledValues = [], // just values
      createable = false
    } = this.props;

    const hasError = showErrors && errorMessage;

    const derivedOptions = options.map((option) => ({
      value: option.value,
      label: option.label + (disabledValues.indexOf(option.value) !== -1 ? " (already added)" : ""),
      disabled: disabledValues.indexOf(option.value) !== -1
    }));

    return (
      <div className={"pt-form-group"}>
        {label && (
          <label className="pt-label">
            {label + " "}
            {required && (
              <span className="f5 pt-text-muted">(required)</span>
            )}
          </label>
        )}
        <div className={"pt-form-content" + (hasError ? " pt-intent-danger" : "")}>
          <div className={"pt-select " + (hasError ? " pt-intent-danger" : "")}>
            {createable ? (
              <CreateableSelect
                name={name}
                isMulti
                closeMenuOnSelect={false}
                value={this.props.getValue() || ''}
                onChange={this.changeValue}
                options={derivedOptions}
              />
            ) : (
              <Select
                name={name}
                isMulti
                closeMenuOnSelect={false}
                value={this.props.getValue() || ''}
                onChange={this.changeValue}
                options={derivedOptions}
              />
            )}
          </div>
          <div className="pt-form-helper-text">
            <div className="inline-error">
              {(showErrors && errorMessage) ? (errorMessage) :
                (showErrors && showRequired) ? "Field is required." : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withFormsy(MultiSelect);