import React from 'react'
import {Spinner} from '@blueprintjs/core'
import FullHeightCalculator from "./FullHeightCalculator";

class AreaSpinner extends React.Component {
  render() {
    const { spinnerProps } = this.props;
    return (
      <FullHeightCalculator className="grid-row v-align h-align">
        <Spinner {...spinnerProps} />
      </FullHeightCalculator>
    )
  }
}

AreaSpinner.defaultProps = {
  spinnerProps: {}
}

export default AreaSpinner;
